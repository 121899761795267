import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    activeTab: 'profile',
};

export const healerMyAccountSlice = createSlice({
    name: 'healerMyAccount',
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setActiveTab } = healerMyAccountSlice.actions;
export default healerMyAccountSlice.reducer;