import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    availableBookingTypes: [],
    availableDates: [],
    availableSlots: [],
    selectedAppointment: null,
    selectedBookingType: [],
    selectedDate: null,
    selectedSlot: null,
    dateSliderPayload: null
};

export const bookAppointmentSlice = createSlice({
    name: 'bookAppointment',
    initialState,
    reducers: {
        setBookingAvailableTypes: (state, action) => {
            state.availableBookingTypes = action.payload;
        },
        setAvailableDates: (state, action) => {
            state.availableDates = action.payload;
        },
        setAvailableSlots: (state, action) => {
            state.availableSlots = action.payload;
        },
        setSelectedAppointment: (state, action) => {
            state.selectedAppointment = action.payload;
        },
        setSelectedBookingType: (state, action) => {
            state.selectedBookingType = action.payload;
        },
        setSelectedDate: (state, action) => {
            state.selectedDate = action.payload;
        },
        setDateSliderPayload: (state, action) => {
            state.dateSliderPayload = action.payload;
        },
        setSelectedSlot: (state, action) => {
            state.selectedSlot = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setBookingAvailableTypes, setAvailableDates, setAvailableSlots, setDateSliderPayload, setSelectedAppointment, setSelectedBookingType, setSelectedDate, setSelectedSlot } = bookAppointmentSlice.actions;
export default bookAppointmentSlice.reducer;