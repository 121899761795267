import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    session: [],
    userData: [],
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setSession: (state, action) => {
            state.session = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setSession, setUserData } = userSlice.actions;
export default userSlice.reducer;