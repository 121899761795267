import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    countries: [],
    states: [],
    country: '',
    state: '',
};

export const healerBusinessDetailSlice = createSlice({
    name: 'healerBusinessDetails',
    initialState,
    reducers: {
        setCountries: (state, action) => {
            state.countries = action.payload;
        },
        setStates: (state, action) => {
            state.states = action.payload;
        },
        setSelectedCountry: (state, action) => {
            state.country = action.payload;
        },
        setSelectedState: (state, action) => {
            state.state = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setCountries, setStates, setSelectedCountry, setSelectedState } = healerBusinessDetailSlice.actions;
export default healerBusinessDetailSlice.reducer;