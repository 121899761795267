import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    editorLoaded: false,
    modalities: [],
    symptoms: [],
    languages: [],
    bookingTypes: [],
    selectedImage: null,
    selectedModalities: [],
    selectedSymptoms: [],
    selectedLanguages: [],
    selectedBookingTypes: [],
    selectedProfileSummary: '',
};

export const healerProfileSlice = createSlice({
    name: 'healerProfile',
    initialState,
    reducers: {
        setEditorLoaded: (state, action) => {
            state.editorLoaded = action.payload
        },
        setModalities: (state, action) => {
            state.modalities = action.payload;
        },
        setSymptoms: (state, action) => {
            state.symptoms = action.payload;
        },
        setLanguages: (state, action) => {
            state.languages = action.payload;
        },
        setBookingTypes: (state, action) => {
            state.bookingTypes = action.payload;
        },
        setSelectedImage: (state, action) => {
            state.selectedImage = action.payload;
        },
        setSelectedModalities: (state, action) => {
            state.selectedModalities = action.payload;
        },
        setSelectedSymptoms: (state, action) => {
            state.selectedSymptoms = action.payload;
        },
        setSelectedLanguages: (state, action) => {
            state.selectedLanguages = action.payload;
        },
        setSelectedBookingTypes: (state, action) => {
            state.selectedBookingTypes = action.payload;
        },
        setSelectedProfileSummary: (state, action) => {
            state.selectedProfileSummary = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setSymptoms, setEditorLoaded, setBookingTypes, setLanguages, setModalities, setSelectedImage, setSelectedBookingTypes, setSelectedModalities, setSelectedSymptoms, setSelectedLanguages, setSelectedProfileSummary } = healerProfileSlice.actions;
export default healerProfileSlice.reducer;