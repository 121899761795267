import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    years: [],
    educationTypes: [],
};

export const healerEducationSlice = createSlice({
    name: 'healerEducation',
    initialState,
    reducers: {
        setYears: (state, action) => {
            state.years = action.payload;
        },
        setEducationTypes: (state, action) => {
            state.educationTypes = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setYears, setEducationTypes } = healerEducationSlice.actions;
export default healerEducationSlice.reducer;