import 'bootstrap/dist/css/bootstrap.min.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-toastify/dist/ReactToastify.css';
import '@/styles/all.min.css';
import '@/styles/fonts/remixicon.css';
import '@/styles/mmenu.css';
import '@/styles/global.css';
import '@/styles/responsive.css';
import '@/styles/mobile-responsive.css';

import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { store } from '@/redux/store';
import PageLoader from '@/components/common/page-loader';
import moment from "moment-timezone";

var $ = require("jquery");
if (typeof window !== "undefined") {
  window.$ = window.jQuery = require("jquery");
}

export default function App({ Component, pageProps: { session, ...pageProps } }) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page) => page);
  moment.tz.setDefault("US/Mountain");

  return (
    <>
      <PageLoader />
      <SessionProvider session={session}>
        <Provider store={store}>
          {getLayout(<Component {...pageProps} />)}
          <ToastContainer
            position="bottom-right"
            hideProgressBar={false}
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </Provider>
      </SessionProvider>
      <Script src="/js/jquery-min.js" />
      <Script src="/js/mmenu.js" />
      <Script src="/js/main.js" />
    </>
  )
}
