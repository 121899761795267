import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    paymentData: [],
    stripeCustomer: {},
    productPriceId: "",
    stripeSubscription: {},
    healerSubscriptionData: {},
};

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        setPaymentData: (state, action) => {
            state.paymentData = action.payload;
        },
        setStripeCustomer: (state, action) => {
            state.stripeCustomer = action.payload;
        },
        setProductPriceId: (state, action) => {
            state.productPriceId = action.payload;
        },
        setStripeSubscription: (state, action) => {
            state.stripeSubscription = action.payload;
        },
        setHealerSubscriptionData: (state, action) => {
            state.healerSubscriptionData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setHealerSubscriptionData, setPaymentData, setStripeCustomer, setProductPriceId, setStripeSubscription } = paymentSlice.actions;
export default paymentSlice.reducer;