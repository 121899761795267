import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
  editorLoaded: false,
  editClass: "",
  categoryTypes: [],
  modalityTypes: [],
  bookingTypess: [],
  selectedFormData: {
    name: "",
    categoryType: "",
    modalityType: "",
    bookingType: "",
    description: "",
    dates: [],
    duration: "",
    price: "",
    applicants: "",
    hours: [],
    fee: "",
    shortDescription: "",
    lessons:[]
  },
};

export const healerClassSlice = createSlice({
  name: "healerClass",
  initialState,
  reducers: {
    setEditorLoaded: (state, action) => {
      state.editorLoaded = action.payload ?? false;
    },
    setEditClass: (state, action) => {
      state.editClass = action.payload;
    },
    setCategoryTypes: (state, action) => {
      state.categoryTypes = action.payload;
    },
    setModalityTypes: (state, action) => {
      state.modalityTypes = action.payload;
    },
    setBookingTypes: (state, action) => {
      state.bookingTypes = action.payload;
    },
    setSelectedName: (state, action) => {
      state.selectedFormData.name = action.payload;
    },
    setSelectedPrice: (state, action) => {
      state.selectedFormData.price = action.payload;
    },
    setSelectedApplicants: (state, action) => {
      state.selectedFormData.applicants = action.payload;
    },
    setSelectedHours: (state, action) => {
      state.selectedFormData.hours = action.payload;
    },
    setSelectedFee: (state, action) => {
      state.selectedFormData.fee = action.payload;
    },
    setSelectedCategoryType: (state, action) => {
      state.selectedFormData.categoryType = action.payload;
    },
    setSelectedModalityType: (state, action) => {
      state.selectedFormData.modalityType = action.payload;
    },
    setSelectedBookingType: (state, action) => {
      state.selectedFormData.bookingType = action.payload;
    },
    setSelectedDescription: (state, action) => {
      state.selectedFormData.description = action.payload;
    },
    setSelectedShortDescription: (state, action) => {
      state.selectedFormData.shortDescription = action.payload;
    },
    setSelectedSchduleDates: (state, action) => {
      state.selectedFormData.dates = action.payload;
    },
    setSelectedDuration: (state, action) => {
      state.selectedFormData.duration = action.payload;
    },
    setClassForm: (state, action) => {
      state.selectedFormData = action.payload;
    },
    setLessonsForm: (state, action) => {
      state.selectedFormData.lessons = action.payload;
    },
    resetClassForm: (state, action) => {
      state.selectedFormData = initialState.selectedFormData;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(HYDRATE, (state, { payload }) => {
      //
    });
  },
});

export const {
  setEditorLoaded,
  setCategoryTypes,
  setModalityTypes,
  setBookingTypes,
  setSelectedName,
  setEditClass,
  setSelectedPrice,
  setSelectedApplicants,
  setSelectedHours,
  setSelectedFee,
  setSelectedCategoryType,
  setSelectedModalityType,
  setSelectedBookingType,
  setSelectedDescription,
  setSelectedShortDescription,
  setSelectedSchduleDates,
  setSelectedDuration,
  setClassForm,
  setLessonsForm,
  resetClassForm,
} = healerClassSlice.actions;
export default healerClassSlice.reducer;
