import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import styles from '../../styles/PageLoader.module.css';
import Image from "next/image";

const PageLoader = () => {
    const router = useRouter();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        router.events.on('routeChangeStart', () => setLoading(true));
        router.events.on('routeChangeComplete', () => setLoading(false));
        router.events.on('routeChangeError', () => setLoading(false));
        return () => {
            router.events.off('routeChangeStart', () => setLoading(true));
            router.events.off('routeChangeComplete', () => setLoading(false));
            router.events.off('routeChangeError', () => setLoading(false));
        };
    }, [router.events]);

    return loading &&
        (<div className={styles.overlay}>
            <div className={styles.loader}>
                <Image width={151} height={159} src="/images/logo.svg" alt="Zenkify Logo" />
            </div>
        </div>);
};

export default PageLoader;