import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    categoryTypes: [],
    states: [],
    name:'',

    filters: {
        name: '',
        state: [],
        city: '',
        sorting: '',
        category: [],
    },
};

export const healerCourseSlice = createSlice({
    name: 'healerCourse',
    initialState,
    reducers: {
        setStates: (state, action) => {
            state.states = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setCategoryTypes: (state, action) => {
            state.categoryTypes = action.payload;
        },
        setSelectedState: (state, action) => {
            state.filters.state = action.payload;
        },
        setSelectedCategoryType: (state, action) => {
            state.filters.category = action.payload;
        },
        setSelectedCity: (state, action) => {
            state.filters.city = action.payload;
        },
        setSelectedName: (state, action) => {
            state.filters.name = action.payload;
        },
        setSelectedSorting: (state, action) => {
            state.filters.sorting = action.payload;
        },
        setEmptyFilter: (state, action) => {
            state.filters = initialState.filters;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setEmptyFilter, setCategoryTypes, setName, setSelectedSorting, setStates, setSelectedName, setSelectedCategoryType, setSelectedCity, setSelectedState } = healerCourseSlice.actions;
export default healerCourseSlice.reducer;