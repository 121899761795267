import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    editorLoaded: false,
    years: [],
    categoryTypes: [],
};

export const healerExperienceSlice = createSlice({
    name: 'healerExperience',
    initialState,
    reducers: {
        setYears: (state, action) => {
            state.years = action.payload;
        },
        setCategoryTypes: (state, action) => {
            state.categoryTypes = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setYears, setCategoryTypes } = healerExperienceSlice.actions;
export default healerExperienceSlice.reducer;