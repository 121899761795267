import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    interval: [],
    bookingInterval: [],
    days: [],
    availability: {},
    holidays: [],
    openHours:[],
    closeHours:[]
};

export const healersTimeAvailabilitySlice = createSlice({
    name: 'healersTimeAvailability',
    initialState,
    reducers: {
        setInterval: (state, action) => {
            state.interval = action.payload;
        },
        setBookingInterval: (state, action) => {
            state.bookingInterval = action.payload;
        },
        setDays: (state, action) => {
            state.days = action.payload;
        },
        setAvailabililty: (state, action) => {
            state.availability = action.payload;
        },
        setHolidays: (state, action) => {
            state.holidays = action.payload;
        },
        setOpenHours: (state, action) => {
            state.openHours = action.payload;
        },
        setCloseHours: (state, action) => {
            state.closeHours = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setInterval, setBookingInterval, setDays, setAvailabililty, setHolidays, setOpenHours, setCloseHours } = healersTimeAvailabilitySlice.actions;
export default healersTimeAvailabilitySlice.reducer;