import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {

};

export const customerProfileSlice = createSlice({
    name: 'customerProfile',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { } = customerProfileSlice.actions;
export default customerProfileSlice.reducer;