import { createSlice } from "@reduxjs/toolkit";
import { HYDRATE } from "next-redux-wrapper";

const initialState = {
    name: "",
    selected_languages:[],
    selected_languages_symp:[],
    filters: {
        name: '',
        state: '',
        city: '',
        symptoms: [],
        sorting: '',
        ratings: [],
        modalities: [],
        bookingTypes: [],
    },
};

export const healersPageSlice = createSlice({
    name: 'healersPage',
    initialState,
    reducers: {
        setName: (state, action) => {
            state.name = action.payload;
        },
        set_Selected_languages: (state, action) => {
            state.selected_languages = action.payload;
        },
        set_Selected_languages_symp: (state, action) => {
            state.selected_languages_symp = action.payload;
        },
        setFilter: (state, action) => {
            state.filters = { ...initialState.filters, ...action.payload };
        },
        setEmptyFilter: (state, action) => {
            state.filters = initialState.filters;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(HYDRATE, (state, { payload }) => {
            //
        })
    }
});

export const { setEmptyFilter, setStates, setName, setFilter, set_Selected_languages, set_Selected_languages_symp } = healersPageSlice.actions;
export default healersPageSlice.reducer;