import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import userReducer from "./features/userSlice";
import healerMyAccountReducer from "./features/healer/my-account/myAccountSlice";
import healersPageReducer from "./features/healer/healersPageSlice.js";
import healerProfileReducer from "./features/healer/my-account/profileSlice";
import healerBusinessDetailReducer from "./features/healer/my-account/businessDetailSlice";
import healerEducationReducer from "./features/healer/my-account/educationSlice";
import healerExperienceReducer from "./features/healer/my-account/experienceSlice";
import CustomerProfileReducer from "./features/customer/profileSlice";
import healerClassReducer from "./features/healer/my-account/classSlice";
import healerCourseReducer from "./features/healerCourseSlice";
import healersTimeAvailabilityReducer from "./features/healer/healersTimeAvailabilitySlice";
import paymentReducer from "./features/paymentSlice";
import bookAppointmentReducer from "./features/bookAppointmentSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    healerMyAccount: healerMyAccountReducer,
    healerProfile: healerProfileReducer,
    healerBusinessDetail: healerBusinessDetailReducer,
    healersPage: healersPageReducer,
    healerEducation: healerEducationReducer,
    healerExperience: healerExperienceReducer,
    customerProfileData: CustomerProfileReducer,
    healerClass: healerClassReducer,
    healerCourse: healerCourseReducer,
    healersTimeAvailability: healersTimeAvailabilityReducer,
    payment: paymentReducer,
    bookAppointment: bookAppointmentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: true,
});
